.updateprofile input.nam[type="file"] {
  color: #fff;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  text-align: left;
  background: transparent;
  border: 1px gold solid;
  display: flex;
  width: 100%;
  border-radius: 3px;
  padding: 9px;
  margin-bottom: 22px;
}
.updateprofile input.nam[type="email"] {
  color: #fff;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  text-align: left;
  background: transparent;
  border: 1px gold solid;
  display: flex;
  width: 100%;
  border-radius: 3px;
  padding: 9px;
  margin-bottom: 22px;
}


#updateimage{
  margin-right: auto;
    /* right: 40px; */
    left: 114px;

}

.update_image{
  width: 129px;
  margin-bottom: 13px;
  height: 100px;
}



.Toastify__toast {
 
  position: relative;
  touch-action: none;
  min-height: var(--toastify-toast-min-height);
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 8px;
  border-radius: var(--toastify-toast-bd-radius);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  max-height: var(--toastify-toast-max-height);
  font-family: var(--toastify-font-family);
  cursor: default;
  direction: ltr;
  z-index: 0;
  overflow: hidden;
  background: #121212!important;
  color: white !important;
  border: solid 1px gold !important;
}


.updateprofile textarea.nam[type="text"] {
    color: #fff;
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    text-align: left;
    background: transparent;
    border: 1px gold solid;
    display: flex;
    width: 100%;
    border-radius: 3px;
    padding: 9px;
    margin-bottom: 22px;
  }
  