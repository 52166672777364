.payment {
  background-color: #121212;
  padding: 133px 0px;
  border-style: solid;
  border-width: 6px 0px;
  border-image: linear-gradient(
      #f5d77f 100%,
      #e1ba60 100%,
      #f5d77f 100%,
      #f5d77f 100%,
      #b07515 100%
    )
    1;
  border-radius: 10px;
}

.payment h5.pay {
  font-family: "Deloire";
  font-size: 38.73px;
  font-weight: 400;
  line-height: 44.11px;
  text-align: center;
  color: #fff;
}
.login-forms {
  margin: 0 auto;
  flex-wrap: wrap;
  position: relative;
}
put[type=number] {
  -webkit-appearance: textfield; /* Chrome, Safari, Opera */
  appearance: textfield; /* Other modern browsers */
  margin: 0; /* Optional: Remove default input margin */
}

.login-forms input.nam[type="number"] {
  color: #fff;
  font-family: "Poppins";
  font-size: 16px;
  -webkit-appearance: textfield!important;  
  appearance: textfield!important ;  
  margin: 0!important; 

  font-weight: 300;
  line-height: 24px;
  text-align: left;
  background: transparent;
  border: 1px gold solid;
  display: flex;
  width: 100%;
  border-radius: 3px;
  padding: 9px;
  margin-bottom: 22px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}
.login-forms input.nam[type="text"] {
  color: #fff;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  text-align: left;
  background: transparent;
  border: 1px gold solid;
  display: flex;
  width: 100%;
  border-radius: 3px;
  padding: 9px;
  margin-bottom: 22px;
}
.login-forms input.card[type="number"] {
  color: #fff;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  text-align: left;
  background: transparent;
  border: 1px gold solid;
  display: flex;
  width: 100%;
  border-radius: 3px;
  padding: 9px;
  margin-bottom: 22px;
}

.drop {
  gap: 10px;
}
.slct {
  align-items: center;
  display: flex;
  justify-content: space-between;
  /* gap: 250px; */
}
.slct-first {
  display: flex;
  column-gap: 50px;
}
.drop1 {
  display: flex;
  gap: 10px;
}
.login-forms select {
  color: #fff;
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 300;
  line-height: 24px;
  text-align: left;
  background: transparent;
  border: 1px gold solid;

  width: 100%;
  height: 100%;
  border-radius: 5px;
  padding: 10px 20px;
  margin-bottom: 22px;
  background-color: #121212;
}

.login-forms input.cv[type="number"] {
  color: #fff;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  text-align: left;
  background: transparent;
  border: 1px gold solid;
  display: flex;
  width: 110px;
  border-radius: 5px;
  padding: 10px 20px;
  margin-bottom: 22px;
}

.login-forms label.namin {
  color: #fff;
  text-transform: uppercase;
  font-family: "Deloire";
  font-size: 18px;
  font-weight: 400;
  line-height: 23.38px;
  text-align: left;
}
.login-forms input.state[type="text"] {
  color: #fff;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  text-align: center;
  background: transparent;
  border: 1px gold solid;
  display: flex;
  width: 110px;
  border-radius: 5px;
  padding: 10px 20px;
  margin-bottom: 22px;
}
.login-forms input.code[type="number"] {
  color: #fff;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  text-align: center;
  background: transparent;
  border: 1px gold solid;
  display: flex;
  width: 110px;
  border-radius: 5px;
  padding: 10px 20px;
  margin-bottom: 22px;
}


.custom_element_input{
  color: #fff;
  font-family: "Poppins";
  font-size: 16px;
  appearance: textfield !important;
  margin: 0 !important;
  font-weight: 300;
  line-height: 24px;
  text-align: left;
  background: transparent;
  border: 1px gold solid;
  width: 100%;
  border-radius: 3px;
  padding: 13px;
  height: 100%;
}

.sub {
  background-image: url("../../Asserts/images/home-btn-bk.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  color: #000;
  padding: 16px;
  margin: 10px 0;
  border: none;
  cursor: pointer;
  width: 196px;
  font-family: "Poppins";
  font-size: 15px;
  font-weight: 600;
  /* border-radius: 6px; */
  letter-spacing: 0.02em;
  background-color: transparent;
}

.payment img.slash {
  height: 44px;
  width: 7px;
}
.payment img.cancel {
  position: absolute;
  left: -20%;
  top: -40px;
}

.select-cvv {
  justify-content: start;
  column-gap: 100px;
}

@media (max-width: 1600px) {
  .payment img.cancel {
    left: -13%;
  }
}

@media (max-width: 1024px) {
  .payment {
    padding: 50px 0px;
  }
}

@media (max-width: 480px) {
  .slct-first {
    column-gap: 10px;
  }
  .select-cvv {
    justify-content: space-between;
    column-gap: unset;
  }
  .payment img.cancel {
    left: 0;
  }
}

@media (max-width: 428px) {
  .sub {
    width: 115px;
  }
  .login-forms input.state[type="text"] {
    width: 80px;
  }
  .login-forms input.cv[type="number"] {
    width: 102px;
  }
  .login-forms select {
    width: 90px;
    padding: 10px 4px;
  }
}
@media (max-width: 991px) {
.login-forms label.namin{
font-size: 13px;
}
}
@media (max-width: 1280px) {
.payment h5.pay {
  padding-top: 30px;
}

}
