.updateprofile input.nam[type="file"] {
  color: #fff;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  text-align: left;
  background: transparent;
  border: 1px gold solid;
  display: flex;
  width: 100%;
  border-radius: 3px;
  padding: 9px;
  margin-bottom: 22px;
}
.updateprofile input.nam[type="email"] {
  color: #fff;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  text-align: left;
  background: transparent;
  border: 1px gold solid;
  display: flex;
  width: 100%;
  border-radius: 3px;
  padding: 9px;
  margin-bottom: 22px;
}

.updateselect {
  color: #fff;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  text-align: left;
  background: transparent;
  border: 1px gold solid;
  /* display: flex; */
  width: 100%;
  border-radius: 3px;
  padding: 9px;
  margin-bottom: 22px;
}

.updateselect .css-1s2u09g-control {
  color: white !important; /* Control text color */
}

.updateselect .css-1s2u09g-single-value {
  color: white !important ; /* Single value text color */
}

.updateselect .css-1s2u09g-option {
  color: white !important; /* Option text color */
}

.css-13cymwt-control {
  color: white !important;
}
.css-qbdosj-Input {
  visibility: visible;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  display: inline-grid;
  grid-area: 1 / 1 / 2 / 3;
  grid-template-columns: 0 min-content;
  margin: 2px;
  padding-bottom: 2px;
  padding-top: 2px;
  /* color: hsl(0, 0%, 20%); */
  box-sizing: border-box;
  color: white !important;
}

/* .css-1jqq78o-placeholder {
  color: #fff;
} */

.login-forms input.nam::placeholder {
  color: #fff !important;
}

.login-forms select placeholder {
  color: #fff !important;
}

.updateselect::placeholder {
  color: #fff !important;
}
.updateselect .react-select__placeholder {
  color: white !important;
}

.react-select__placeholder {
  color: white;
}

select {
  color: white !important;
}

.css-9jq23d {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 2px;
  color: white !important;
  font-size: 85%;
  padding: 3px 3px 3px 6px;
  box-sizing: border-box;
}

.payment .css-1jqq78o-placeholder {
  color: #fff !important;
}
.payment .css-1dimb5e-singleValue {
  color: #fff !important;
}
.css-1dimb5e-singleValue {
  color: #ffffff !important ;
}
/* updateselect */

.updateselect .react-select__placeholder::placeholder {
  color: white !important;
}

.verifytext {
  color: #f5d77f;
  justify-content: center !important;
  margin: auto;
  width: 31%;
  text-align: center;
  line-height: 65px;
  font-size: xxx-large;
}

.updateprofile textarea.nam[type="text"] {
  color: #fff;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  text-align: left;
  background: transparent;
  border: 1px gold solid;
  display: flex;
  width: 100%;
  border-radius: 3px;
  padding: 9px;
  margin-bottom: 22px;
}
