.model_profile_section_main {
  background-color: #000;
  padding: 110px 0px;
  background-image: url("../../Asserts/images/hotModel-bk.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed !important;
}

.user_profile_picture img {
  height: 100px;
  width: 100px;
  object-fit: cover;
  position: relative;
  margin-top: -50px;
}

.model_profile_section_main .hot_model_name {
  font-size: 18px;
  font-weight: 500;
}
a.heart {
  z-index: 9999;
}




.imagemessage {
  max-height: 500px !important;
  max-width: 100% !important;
  height: auto !important;
  width: auto !important;
}





.checkicon {
  width: 25px;
  height: 27px;
  position: absolute;
  /* background-color: #FFF; */
  bottom: 1px;
  right: 8%;
  font-size: 13px;
  /* border-radius: 5px; */
  box-shadow: 10px 10px 30px rgba(24, 24, 51, 0.07);
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  color: black;
  background-color: white;
  border-radius: 4px;
}

.name-edit-icon {
  width: 25px;
  height: 25px;
  position: absolute;
  /* background-color: #FFF; */
  bottom: 14px;
  right: 20%;
  font-size: 13px;
  border-radius: 5px;
  box-shadow: 10px 10px 30px rgba(24, 24, 51, 0.07);
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  color: white;
}
.model_profile_section_main {
  .framePic {
    position: absolute;
    /* top: -86px; */
    /* left: -69px; */
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
  }

  .frameimg {
    /* height: 494px !important;
      width: 420px !important; */
    width: 100% !important;
    height: 91% !important;
  }
}

.model_profile_section_main .model_have_a_look_btn {
  position: absolute;
  /* bottom: 0; */
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  width: 100%;
  background-color: #0009;
  height: 0;
}

.model_profile_section_main .second_model_card:hover .model_have_a_look_btn {
  bottom: 0px;
  padding-top: 22px;
  height: 112px;
  width: 97%;
  top: auto;
}

.model_profile_section_main .first_model_card:hover .model_have_a_look_btn {
  bottom: 0px;
  padding-top: 22px;
  height: 112px;
  width: 97%;
  top: auto;
}

.model_profile_section_main .second_model_card:hover .be_nice_span,
.model_profile_section_main .first_model_card:hover .be_nice_span {
  color: #fff;
  display: block;
  font-size: 14px;
  font-weight: 500;
  padding-top: 10px;
}

.fa-heart:before {
  content: "\f004";
  color: #dd3737;
  height: 37px !important;
  width: 52px;
}

#model_card_bottom_corner_imgs {
  bottom: 34px !important;
  left: -10px;
  position: absolute;
  transform: rotate(90deg);
}
.user_access {
  display: inline-block;
  font-size: 12px;
  font-weight: 700;
  color: #494949;
  text-transform: uppercase;
}

.user_profile_desc {
  color: #bebebe;
  text-align: justify;
  font-size: 14px;
  font-weight: 400;
}

.followers_title {
  display: block;
  text-transform: uppercase;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  padding-bottom: 3px;
}

.followers_number {
  display: block;
  padding: 5px 0px;
  font-size: 20px;
  font-weight: 700;
  background-image: url("../../Asserts/images/user-profile-span-bk.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.following_number_btn {
  display: block;
  background-image: url("../../Asserts/images/user-profile-span-bk.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 10px 27px;
  font-size: 13px;
  font-weight: 500;
  font-family: "CircularStd-Book", sans-serif;
  color: #fff;
  width: 100%;
}

.no_of_follows {
  display: inline-block;
  background: -webkit-linear-gradient(#ffe37f, #8e6200);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.model_profile_section_main .sign_actionBtn {
  background-image: url("../../Asserts/images/have-a-look-btn-bk.png");
  font-size: 10px;
  color: #fff;
  padding: 10px 28px;
  font-weight: 400;
}

.give_tip_btn {
  background-image: url("../../Asserts/images/login-btn-bk.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  border: none;
  background-color: transparent;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  padding: 6px 0px;
  margin-top: 10px;
}
.user_profile_bk_img img {
  width: 100%;
}

.rewards_btn {
  background-image: url("../../Asserts/images/model-profile-rewards-bk.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  border: none;
  background-color: transparent;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  padding: 6px 0px;
  margin-top: 10px;
}

.user_profile_info {
  background-color: #000;
  padding: 0px 14px 24px;
}

.more_profiles_main {
  margin-top: 50px;
  background-color: #0b0b0b;
  border-image: url("../../Asserts/images/photo-frame.png");
  border-image-outset: 1px;
  border-image-width: 4px;
  border-image-slice: 20;
}

.more_profiles_like_this_title {
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  margin: 0px;
  padding: 12px 0px;
  background-color: #161616;
}

.more_users_profile_img img {
  height: 40px;
  width: 40px;
  object-fit: cover;
}

.more_user_profile_details {
  display: flex;
  align-items: center;
  gap: 8px;
}

.other_user_name {
  margin: 0px;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
}

.other_user_access {
  display: inline-block;
  font-size: 10px;
  color: #7d7d7d;
}

.other_user_info {
  line-height: 13px;
}

.more_profiles_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 22px;
}

.user_profile_follow_btn {
  border: none;
  background-image: url("../../Asserts/images/create-account-btn-bk.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-color: transparent;
  color: #fff;
  padding: 5px 15px;
  font-size: 10px;
  text-transform: uppercase;
}

.all_other_users_profiles {
  padding: 16px 10px;
}

.model_profile_section_main .second_model_card_img {
  background-color: initial;
  border-image-outset: 5px;
  border-image-width: auto;
}

.model_profile_section_main .swiper-slide-active,
.model_profile_section_main .swiper-slide {
  padding: 5px 7px;
}

/* .model_main_section{
    background-image: url("../../Asserts/images/hotModel-bk.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding-bottom: 55px;
} */

/* .user_profile_follow_btn{

} */

.edit_icon_img {
  display: inline-block;
  position: absolute;
  bottom: 84px;
  z-index: 50;
  right: 10px;
}

.edit_icon_img img {
  height: 20px;
  width: 20px;
}

.share_icon_img {
  display: inline-block;
  position: absolute;
  bottom: 48px;
  z-index: 50;
  right: 10px;
}

.share_icon_img img {
  height: 20px;
  width: 20px;
}

.boost_icon_img {
  display: inline-block;
  position: absolute;
  bottom: 14px;
  z-index: 50;
  right: 10px;
}

.boost_icon_img img {
  height: 20px;
  width: 20px;
}

@media (max-width: 1024px) {
  .sec-pstmsg-btns {
    flex-wrap: wrap;
  }
  .sec-pstmsg-btns button {
    width: 100% !important;
    margin-bottom: 12px;
  }
  .all_filtered_btn {
    gap: 20px;
  }
  .model_profile_section_main {
    padding-bottom: 50px;
  }
}

@media (max-width: 991px) {
  .user_profile_desc {
    text-align: left;
  }

  .rewards_btn {
    font-size: 10px;
  }

  .give_tip_btn {
    font-size: 10px;
  }
}
@media (max-width: 768px) {
  .all_filtered_btn {
    gap: 10px;
  }

  .model_profile_section_main .sign_actionBtn {
    padding: 10px 18px;
  }
}

@media (max-width: 736px) {
  .model_profile_section_main .sign_actionBtn {
    padding: 10px 18px;
    font-size: 16px;
  }
  .give_tip_btn {
    font-size: 16px;
  }
  .rewards_btn {
    font-size: 16px;
  }
  .model_profile_section_main .all_filtered_btn .sign_actionBtn {
    padding: 10px 17px;
    font-size: 11px;
  }
  .user_profile_info .user_profile_desc {
    text-align: center;
  }
}

@media (max-width: 480px) {
  .model_profile_section_main .all_filtered_btn .sign_actionBtn {
    padding: 10px 14px;
    font-size: 10px;
  }
}
@media (max-width: 428px) {
  .all_filtered_btn {
    gap: 15px;
  }
}

.main_chat_div {
  /* height: 500px!important;  */
  overflow-y: auto !important;
}



.inbox_message{
  margin: auto;
  text-align: center;
  color: #edd882;
}