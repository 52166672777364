.model_section {
  /* background-color: #000; */
  padding-top: 126px;
}

.model_section .main_searchBar {
  width: 40%;
}

.all_filters_with_search {
  display: flex;
  justify-content: center;
  gap: 40px;
}

.product_description_cardText {
  display: -webkit-box;
  -webkit-line-clamp: 1 !important;
  -webkit-box-orient: vertical;
  overflow: hidden;
}



.not-found{
 
  text-align: center;
  margin: auto;
  color: #c1a56e;
  font-size: xxx-large;
  font-weight: 500;
  padding: 161px;

}
.sortby_label {
  color: #fff;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 400;
  margin-right: 10px;
  display: inline-block;
}

.all_filtered_btn {
  display: flex;
  justify-content: center;
  gap: 40px;
  align-items: center;
  padding: 30px 0px;
  position: relative;
  z-index: 999;
}

.model_section .sign_actionBtn {
  background-image: url("../../Asserts/images/have-a-look-btn-bk.png");
  color: #fff;
  font-weight: 400;
  font-size: 12px;
  overflow: hidden;
}

.model_section .sign_actionBtn:focus {
  box-shadow: none;
}

.model_section .first_model_card:hover .model_card_desc {
  opacity: 1;
}

.online_circle {
  color: #58ca2e;
  display: inline-block;
  font-size: 8px;
}

.hot_model_name {
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
}
.model_section .model_card_desc {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  width: 100%;
  background-color: #0009;
  height: 0;
}
.first_model_card:hover .model_card_desc {
  height: 160px!important;
  transition: 0.5s;
}

.model_section .model_have_a_look_btn {
  position: absolute;
  /* bottom: 0; */
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  width: 100%;
  background-color: #0009;
  height: 0;
}

.model_main_section {
  .framePic {
    position: absolute;
    /* top: -93px; */
    /* left: -72px; */
    top: 0px;
    left: 0px;
    z-index: 10;
    height: 100%;
    width: 100%;
  }

  .framePic img {
    height: 100% !important;
    width: 100% !important;
  }
}

.name_with_status {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.hotmodel_info {
  color: #fff;
}

.send_tip_text {
  color: #f5d77f;
  text-transform: uppercase;
  padding-left: 9px;
  font-weight: 500;
}

.model_card_desc .sign_actionBtn {
  padding: 8px 16px;
  background-image: url("../../Asserts/images/home-btn-bk.png");
  color: #000;
}

.heart_icon {
  position: absolute;
  top: 6px;
  z-index: 30;
  right: 14px;
  color: crimson;
  font-size: 22px;
}

.model_section .second_model_card:hover .model_card_desc {
  opacity: 1;
}

.model_section .second_model_card_img {
  background-color: transparent;
  border-image-outset: 1px;
  border-image-width: auto;
}

.model_section .second_model_card_img img {
  position: relative;
  z-index: -1;
}

.model_section .video_play_icon img {
  height: 40px;
  width: 40px;
}

.model_main_section {
  background-image: url("../../Asserts/images/hotModel-bk.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-bottom: 55px;
}

.model_section {
  background-image: url("../../Asserts/images/top-model-section-bk.png");
  background-repeat: no-repeat;
  background-size: 100% 27%;
}

@media (max-width: 1024px) {
  .model_section .all_filtered_btn .sign_actionBtn {
    padding: 10px 19px;
  }
}

@media (max-width: 991px) {
  .all_filtered_btn {
    gap: 20px;
    padding: 30px 0px;
  }
  .framePic img {
    width: 117%;
    height: 100%;
  }
  .framePic {
    top: -24%;
    left: -24%;
  }
}

@media (max-width: 768px) {
}

@media (max-width: 736px) {
  .all_filtered_btn {
    gap: 10px;
    padding: 30px 0px;
  }

  .all_filters_with_search {
    gap: 20px;
    flex-wrap: wrap;
  }
  .model_section .main_searchBar {
    width: 100%;
  }
  .model_section .all_filtered_btn .sign_actionBtn {
    padding: 10px 10px;
  }
}
@media (max-width: 480px) {
  .all_filtered_btn {
    gap: 15px;
    padding: 30px 0px;
    flex-wrap: wrap;
  }
  .model_section .all_filtered_btn .sign_actionBtn {
    padding: 10px 20px;
  }
  .model_card_bottom_corner_img {
    left: 5%;
  }
  .model_card_top_corner_img {
    right: 5%;
  }
  .framePic img {
    width: 110%;
    height: 100%;
  }
  .framePic {
    top: -24%;
    left: -14%;
  }
}
@media (max-width: 428px) {
  .framePic img {
    width: 117%;
    height: 100%;
  }
  .framePic {
    top: -24%;
    left: -24%;
  }
  .model_card_bottom_corner_img {
    bottom: -3px;
    left: -10px;
  }
  .model_card_top_corner_img {
    top: -4px;
    right: -10px;
  }
}

.model_img {
  width: 25% !important;
  border: 2px solid #c18d30;
  border-radius: 50%;
}
.model_div {
  display: flex;
  align-items: center;
  padding: 13px 10px;
  flex-wrap: wrap;
}
.image_with_text_row {
  display: flex;
  align-items: center;
  gap: 11px;
  width: 45%;
}
.image_with_text_row_two {
  display: flex;
  align-items: center;
  gap: 16px;
}
.profile_name_one {
  font-family: "CircularStd-Book", sans-serif;
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  margin: 0px;
  color: #fff;
  padding: 0px;
  position: relative;
  text-align: center;
}
.unlocked_icon {
  color: #fff;
  font-size: 20px;
  opacity: 0.5;
  padding-right: 6px;
  position: relative;
}
.free_locked_text {
  color: #fff;
  text-align: center;
  font-family: "CircularStd-Bold", sans-serif;
  font-weight: 700;
  font-size: 13px;
  padding: 0px;
  margin-left: 10px;
  margin: 0px;
  margin-left: 10px;
}
.locked_icon {
  color: #9e7d2a;
  font-size: 20px;
  padding-right: 6px;
  position: relative;
}
.lock_text_clr {
  color: #9e7d2a;
}
.numbers_in_icons {
  color: #fff;
  text-align: center;
  font-family: "CircularStd-Bold", sans-serif;
  font-weight: 700;
  font-size: 11px;
  position: absolute;
  top: 23%;
  right: 51%;
}
.numbers_in_icons_two {
  color: #fff;
  text-align: center;
  font-family: "CircularStd-Bold", sans-serif;
  font-weight: 700;
  font-size: 11px;
  position: absolute;
  top: 23%;
  right: 33%;
}
.description_box {
  padding: 0px 13px;
}
.product_heading {
  font-family: "CircularStd-Medium", sans-serif;
  font-size: 15px;
  line-height: 23px;
  color: #fff;
  text-align: left;
  font-weight: 700;
  display: block;
}
.product_heading:hover {
  text-decoration: none;
  color: #fff;
}
.product_description {
  font-size: 13px;
  line-height: 17px;
  color: #d7d7d7;
  text-align: left;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  padding: 0;
}
@media (max-width: 1440px) {
  .numbers_in_icons_two {
    top: 19%;
    right: 26%;
    font-size: 8px;
  }
  .product_heading {
    font-size: 12px;
  }
  .numbers_in_icons {
    font-size: 8px;
    top: 19%;
    right: 48%;
  }
  .model_div {
    gap: 7px;
  }
  .unlocked_icon {
    font-size: 16px;
  }
  .locked_icon {
    font-size: 16px;
  }
  .profile_name_one {
    font-size: 11px;
  }
  .free_locked_text {
    font-size: 12px;
  }
  .image_with_text_row_two {
    gap: 12px;
  }
  .image_with_text_row {
    gap: 5px;
  }
}
@media (max-width: 1024px) {
  .model_div {
  }
}

.view_collection_btn {
  background-color: #16161666;
  border: #ab8b38 1px solid;
  padding: 6px 23px;
  font-size: 13px;
  color: white;
  font-family: "CircularStd-Bold", sans-serif;
  border-radius: 25px;
}
.view_collection_btn:hover {
  background-color: #b99649;
}
/* .view_collection_btn_div{
  text-align: left;
  padding: 0px 10px;
} */
